import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import CONTACT_CONFIG from '../../configs/contactConfig';

const { PT_CONTACT, FOOTER_CONTACT, SOCMED } = CONTACT_CONFIG;

function OjkRecommendationNumber() {
  return (
    <div className="flex flex-col gap-y-2">
      <h4 className="text-md font-bold text-blue-light40">Berstatus direkomendasikan OJK</h4>
      <p className="text-xs font-medium">No. S-272/IK.01/2024</p>
    </div>
  );
}

function Contact() {
  return (
    <div className="md:w-1/3 md:flex md:flex-col md:gap-y-4">
      <div className="flex flex-col gap-y-2">
        <h4 className="text-md font-bold text-blue-light40">Phone</h4>
        <div className="flex flex-col gap-y-1">
          <h6 className="text-sm font-semibold">Office</h6>
          <p className="text-xs font-medium no-underline">{FOOTER_CONTACT.PHONE}</p>
        </div>
        <div className="flex flex-col gap-y-1">
          <h6 className="text-sm font-semibold">Whatsapp</h6>
          <a
            href={FOOTER_CONTACT.WHATSAPP_LINK}
            target="_blank"
            rel="noreferrer"
          >
            <p className="text-xs font-medium">{FOOTER_CONTACT.WHATSAPP}</p>
          </a>
        </div>
      </div>
      <div className="flex flex-col gap-y-2">
        <h4 className="text-md font-bold text-blue-light40">E-mail</h4>
        <a
          href={`mailto:${PT_CONTACT.EMAIL}`}
          target="_blank"
          rel="noreferrer noopener"
          className="text-xs font-medium text-blue-light40"
        >
          {PT_CONTACT.EMAIL}
        </a>
      </div>
      <div className="flex flex-col gap-y-2">
        <h4 className="text-md font-bold text-blue-light40">Follow Us On</h4>
        <div className="flex gap-x-2">
          <a
            href={SOCMED.YOUTUBE}
            target="_blank"
            rel="noreferrer noopener"
          >
            <StaticImage
              src="../../images/socmed/icon-youtube.webp"
              alt="Youtube Icon"
              objectFit="contain"
              className="w-8 h-8"
            />
          </a>
          <a
            href={SOCMED.TIKTOK}
            target="_blank"
            rel="noreferrer noopener"
          >
            <StaticImage
              src="../../images/socmed/icon-tiktok.webp"
              alt="Tiktok Icon"
              objectFit="contain"
              className="w-8 h-8"
            />
          </a>
          <a
            href={SOCMED.X}
            target="_blank"
            rel="noreferrer noopener"
          >
            <StaticImage
              src="../../images/socmed/icon-x.webp"
              alt="X Icon"
              objectFit="contain"
              className="w-8 h-8"
            />
          </a>
          <a
            href={SOCMED.TELEGRAM}
            target="_blank"
            rel="noreferrer noopener"
          >
            <StaticImage
              src="../../images/socmed/icon-telegram.webp"
              alt="Telegram Icon"
              objectFit="contain"
              className="w-8 h-8"
            />
          </a>
          <a
            href={SOCMED.INSTAGRAM}
            target="_blank"
            rel="noreferrer noopener"
          >
            <StaticImage
              src="../../images/socmed/icon-instagram.webp"
              alt="Instagram Icon"
              objectFit="contain"
              className="w-8 h-8"
            />
          </a>
        </div>
      </div>
    </div>
  );
}

function OfficeAddress() {
  return (
    <div className="w-1/3 flex flex-col gap-y-4">
      <h4 className="text-md font-bold text-blue-light40">Office</h4>
      <div className="flex flex-col gap-y-2">
        <h6 className="text-sm font-semibold">Head Office (Surabaya)</h6>
        <div className="flex flex-col">
          <p className="text-xs font-medium">
            IdeaHub Voza Tower, lantai 20
          </p>
          <p className="text-xs font-medium">
            Jl. Mayjen HR. Muhammad No.31
          </p>
          <p className="text-xs font-medium">
            Sukomanunggal, Surabaya
          </p>
          <p className="text-xs font-medium">
            Jawa Timur, 60189
          </p>
        </div>
      </div>
      <div className="flex flex-col gap-y-2">
        <h6 className="text-sm font-semibold">Branch Office (Jakarta)</h6>
        <div className="flex flex-col">
          <p className="text-xs font-medium">
            GoWork Sampoerna Strategic
          </p>
          <p className="text-xs font-medium">
            Square, Lantai 12
          </p>
          <p className="text-xs font-medium">
            Jl. Jend. Sudirman Kav.45 Karet
          </p>
          <p className="text-xs font-medium">
            Semanggi Setiabudi,
          </p>
          <p className="text-xs font-medium">
            Jakarta Selatan, 12930
          </p>
        </div>
      </div>
    </div>
  );
}

function Legality() {
  return (
    <div className="w-1/3 flex flex-col gap-y-4">
      <OjkRecommendationNumber />
      <div className="flex flex-col gap-y-2">
        <h4 className="text-md font-bold text-blue-light40">Terdaftar di</h4>
        <div className="flex gap-x-4">
          <StaticImage
            src="../../images/kominfo-footer.png"
            alt="kominfo-logo"
            objectFit="contain"
            className="w-12"
            placeholder="none"
          />
          <StaticImage
            src="../../images/fintech-footer.png"
            alt="fintech-logo"
            objectFit="contain"
            className="w-20 ml-3 lg:ml-4"
            placeholder="none"
          />
        </div>
      </div>
      <div className="flex flex-col gap-y-2">
        <h4 className="text-md font-bold text-blue-light40">Telah Tersertifikasi</h4>
        <div className="flex gap-x-4">
          <StaticImage
            src="../../images/cbqa-iso-27001.webp"
            alt="iso-sertifikasi-logo"
            objectFit="contain"
            className="w-12"
            placeholder="none"
          />
        </div>
      </div>
    </div>
  );
}

export default function SimpleFooter() {
  return (
    <footer className="relative z-50 p-8 bg-white lg:px-60 lg:py-16">
      <div className="flex flex-col gap-y-4 md:hidden">
        <OjkRecommendationNumber />
        <Contact />
      </div>
      <div className="hidden md:flex md:items-center md:justify-center md:gap-x-8">
        <OfficeAddress />
        <Contact />
        <Legality />
      </div>
    </footer>
  );
}
